@import "../../base/fn";

@weuiGalleryOprHeight: 60px;
.weui-gallery {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    z-index: 1000;
}
.weui-gallery__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: @weuiGalleryOprHeight;
    left: 0;
    background: center center no-repeat;
    background-size: contain;
}
.weui-gallery__opr {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #0D0D0D;
    color: #FFFFFF;
    line-height: @weuiGalleryOprHeight;
    text-align: center;
}
.weui-gallery__del {
    display: block;
}