@import "../../../base/fn";

.weui-cells_checkbox {
    .weui-cell__hd {
        padding-right: @weuiCellInnerGapH;
    }
    .weui-cell {
        &:active {
            background-color: #ECECEC;
        }
    }
    .weui-icon-checked {
        &:before {
            content: '\EA01';
            color: #C9C9C9;
            font-size: 23px;
            display: block;
        }
    }
}

// method2 accessbility
.weui-check {
    // checkbox
    .weui-cells_checkbox & {
        &:checked {
            & + .weui-icon-checked {
                &:before {
                    content: '\EA06';
                    color: #09BB07;
                }
            }
        }
    }
}