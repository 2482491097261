@import "../../../base/fn";

.weui-cell_select {
    padding: 0;
    .weui-select {
        padding-right: 30px;
    }
    .weui-cell__bd{
        &:after{
            content: " ";
            .setArrow(right, 6px, #C8C8CD, 2px);

            position: absolute;
            top: 50%;
            right: @weuiCellGapH;
            margin-top: -3px;
        }
    }
}

.weui-select {
    -webkit-appearance: none;
    border: 0;
    outline: 0;
    background-color: transparent;
    width: 100%;
    font-size: inherit;
    height: @weuiCellHeight;
    line-height: @weuiCellHeight;
    position: relative;
    z-index: 1;
    padding-left: @weuiCellGapH;
}

.weui-cell_select-before {
    padding-right:@weuiCellGapH;
    .weui-select{
        width:@weuiCellLabelWidth;
        box-sizing: border-box;
    }
    .weui-cell__hd {
        position:relative;
        &:after {
            .setRightLine(@weuiCellBorderColor);
        }
        &:before{
            content: " ";
            .setArrow(right, 6px, #C8C8CD, 2px);

            position: absolute;
            top: 50%;
            right: @weuiCellGapH;
            margin-top: -3px;
        }
    }
    .weui-cell__bd {
        padding-left:@weuiCellGapH;
        &:after{
            display:none;
        }
    }
}

.weui-cell_select-after {
    padding-left:@weuiCellGapH;
    .weui-select {
        padding-left:0;
    }
}