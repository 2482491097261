@import "../../base/fn";

.weui-toast {
    position: fixed;
    z-index: 5000;
    width: 7.6em;
    min-height: 7.6em;
    top: 180px;
    left: 50%;
    margin-left: -3.8em;
    background: rgba(40, 40, 40, 0.75);
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF;
}
.weui-icon_toast {
    margin: 22px 0 0;
    display: block;
    &.weui-icon-success-no-circle{
      &:before {
          color: #FFFFFF;
          font-size: 55px;
      }
    }
    &.weui-loading{
      margin:30px 0 0;
      width:38px;
      height:38px;
      vertical-align: baseline;
    }
}

.weui-toast__content {
    margin: 0 0 15px;
}
