@import "../../base/fn";

.weui-tabbar {
    display: flex;
    position: absolute;
    z-index: 500;
    bottom: 0;
    width: 100%;
    background-color: #f7f7fa;

    &:before {
        .setTopLine(#C0BFC4);
    }
}

.weui-tabbar__item {
    display: block;
    flex: 1;
    padding: 7px 0 0;
    font-size: 0;
    color: @weuiTextColorGray;
    text-align: center;
    .setTapColor();

    &.weui-bar__item_on {
        .weui-tabbar__icon, .weui-tabbar__icon > i, .weui-tabbar__label {
            color: #09BB07;
        }
    }
}

.weui-tabbar__icon {
    display: inline-block;
    width: 24px;
    height: 24px;

    i&, > i {
        font-size: 24px;
        color: @weuiTextColorGray;
    }

    img {
        width: 100%;
        height: 100%;
    }

    & + .weui-tabbar__label {
        margin-top: 5px;
        margin-bottom: 3px;
    }
}

.weui-tabbar__label {
    text-align: center;
    color: @weuiTextColorGray;
    font-size: 12px;
}